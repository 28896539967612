/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import SmallFooter from '../components/SmallFooter';
// import { Link } from "react-router-dom";
import Button from '../components/Button';
import InputEmailForm from '../components/InputEmailForm';
import '../assets/css/blobs.css';

// import MAINIMAGE from '../assets/imgs/MAINIMAGE.png';
import SoftSkillLink from '../components/SoftSkillLink';
import Carousel from '../components/Carousel';

function Main() {
  const [SoftSkills, SetSoftSkills] = useState([]);

  const [msg, setMsg] = useState('');
  const [parentState, setParentState] = useState('');
  const wrapperSetParentState = useCallback(val => {
    setParentState(val);
  }, [setParentState]);

  function handleClick(event) {
    event.preventDefault();
    console.log(parentState);
    setMsg('')
    axios.post(`https://teiaexp.carteiraholder.com.br/laravel/public/api/Presentation`, { ...parentState })
      // axios.post("http://localhost:8000/api/Presentation", { email: parentState })
      .then(() => {
        setMsg('Solicitação enviada com sucesso!')
      })
      .catch(err => {
        setMsg(err.response.data.message)
      })
  }

  useEffect(() => {
    axios.get(`https://teiaexp.carteiraholder.com.br/laravel/public/api/ExperienceCategory`)
      .then(res => {
        const SoftSkills = res.data;
        SetSoftSkills(SoftSkills)
      })
  }, [])

  return (
    <>
      <Header />
      <main className="items-center justify-evenly mx-auto mt-1">
        <Carousel />
        <section className="flex flex-col justify-center mx-auto my-4 max-w-screen-lg" id="cards">
          {
            SoftSkills.map((softskill) => (softskill.active === true && <SoftSkillLink
              key={softskill.id}
              id={softskill.id}
              imageSource={`https://teiaexp.carteiraholder.com.br/laravel/storage/app/public/${softskill.image}`}
              h2={softskill.title}
              p={softskill.description}
            />))
          }
        </section>
        <div id="blobs-main2" className="relative">
          <svg className="orange3 absolute left-72 top-20 sm:top-36 sm:left-24" viewBox="0 0 200 200" xmlns="https://www.w3.org/2000/svg">
            <path fill="#FF9980" d="M22.7,-44.9C27.2,-36.7,27.1,-26.1,36.1,-18.3C45.2,-10.4,63.3,-5.2,72.1,5.1C81,15.4,80.4,30.8,69.7,35.7C59,40.6,38.1,35.2,24.7,40.4C11.4,45.7,5.7,61.6,1.1,59.7C-3.4,57.7,-6.9,37.8,-12.8,28.3C-18.8,18.8,-27.3,19.7,-41.4,16.7C-55.6,13.8,-75.4,6.9,-73.3,1.2C-71.1,-4.4,-47,-8.8,-36.6,-18.3C-26.2,-27.9,-29.6,-42.5,-25.8,-51.1C-22,-59.6,-11,-62,-1,-60.4C9.1,-58.7,18.2,-53,22.7,-44.9Z" transform="translate(100 100)" />
          </svg>
          <svg className=" orange4 absolute left-0 top-48 sm:top-12 sm:left-3/4" viewBox="0 0 200 200" xmlns="https://www.w3.org/2000/svg">
            <path fill="#FF9980" d="M36.4,-57.3C46.9,-57,55,-46.7,58.2,-35.5C61.5,-24.3,59.8,-12.1,62.2,1.4C64.7,14.9,71.1,29.9,67,39.6C62.9,49.3,48.3,53.9,35.4,56.5C22.5,59.1,11.2,59.8,1.5,57.1C-8.2,54.4,-16.3,48.4,-22.5,41.9C-28.7,35.4,-32.9,28.4,-37.5,21.3C-42.2,14.3,-47.4,7.1,-54.8,-4.3C-62.2,-15.7,-72,-31.4,-66.5,-37.1C-61,-42.8,-40.3,-38.4,-26.7,-36.9C-13.1,-35.5,-6.5,-37,3.2,-42.5C12.9,-48.1,25.9,-57.6,36.4,-57.3Z" transform="translate(100 100)" />
          </svg>
        </div>
        <section className="relative md:mt-12">
          <article className="md:w-full text-center px-10 mt-10 mb-14 xl:mb-8 flex-1 md:mb-10">
            <h3 className="text-3xl md:text-4xl font-bold">
              Seu caminho para o bem-estar
            </h3>
            <p className="mt-5 md:mx-auto md:w-2/4 md:mt-8 text-lg mac:text-3xl uw:px-64 uw:text-2xl">
              Trabalhar habilidades comportamentais te ajuda não só profissionalmente mas também a viver melhor!
            </p>
            <a target="_blank" rel="noreferrer" href="https://www.teiaexp.com/blog">
              <Button opacity="10" color="primary" text="Leia mais no Blog" />
            </a>
          </article>
        </section>
        <section className="relative bg-tertiary text-center py-4 md:px-10 sm:mt-20 mac:mt-28 uw:mt-48">
          <h3 className="text-center text-lg font-bold xl:text-xl mac:text-2xl uw:text-3xl">Gostaria de usufruir de todos os benefícios do TEIAExp ?</h3>
          <div className="px-5">
            <p className="my-4 text-center text-md mac:text-lg uw:text-2xl">Indique sua empresa</p>
            <InputEmailForm
              // placeholder="Nome"
              parentState={parentState}
              parentStateSetter={wrapperSetParentState}
              parentClass="text-black  mx-auto my-4 w-full bg-tertiary border-2 border-secondary text-black text-sm rounded-3xl block px-2 py-1 focus:outline-none md:max-w-screen-sm uw:text-xl"
            />
            {/* <InputEmailForm
              placeholder="Email corporativo"
              parentState={parentState}
              parentStateSetter={wrapperSetParentState}
              parentClass="mx-auto my-4 w-full bg-tertiary border-2 border-secondary text-black text-sm rounded-3xl block px-2 py-1 focus:outline-none md:max-w-screen-sm"
            /> */}
            {/* <select
              className="accent-primary mx-auto my-4 w-full bg-tertiary border-2 border-secondary text-gray-400 text-sm rounded-3xl block px-2 py-1 focus:outline-none md:max-w-screen-sm focus:bg-secondary"
              name="company-role"
              id="company-role"
              onChange={(event) => {
                event.target.value !== ''
                  ? document.getElementById('company-role').classList.remove('text-gray-400') && document.getElementById('company-role').classList.add('text-black')
                  : document.getElementById('company-role').classList.remove('text-black') && document.getElementById('company-role').classList.add('text-gray-400')
              }}
            >
              <option className="text-gray-400" value="" disabled selected hidden>Como você contribui com a sua empresa?</option>
              <option className="text-white px-2 py-1" value="RH">RH</option>
              <option className="text-white px-2 py-1" value="C-Level">C-Level</option>
              <option className="text-white px-2 py-1" value="Colaborador">Colaborador</option>
            </select> */}
          </div>
          <p className='text-black'>{msg}</p>
          <Button opacity="90" color="primary" text="Enviar" func={handleClick} />
          <svg id="wave1" className="absolute left-0 -top-20 sm:-top-32 md:-top-40 w-full -z-10 md:top-0" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FBD8CA" fillOpacity="1" d="M0,96L30,112C60,128,120,160,180,160C240,160,300,128,360,106.7C420,85,480,75,540,112C600,149,660,235,720,240C780,245,840,171,900,154.7C960,139,1020,181,1080,181.3C1140,181,1200,139,1260,144C1320,149,1380,203,1410,229.3L1440,256L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
          </svg>
        </section>
      </main>
      <SmallFooter />
    </>
  )
}

export default Main;

import React from 'react';
import Header from '../components/Header';
import SmallFooter from '../components/SmallFooter';
import  jumpingImg from '../assets/imgs/illustrations/jumping-2194230.png';
import Button from '../components/Button';
import '../assets/css/reset.css'
import '../assets/css/svg.css';
import '../assets/css/blobs.css';


function About() {
  return (
    <>
      <Header/>
      <section className="mx-auto pt-14 bg-tertiary md:mb-8 relative">
          <h3 className="mb-8 text-center text-3xl font-bold">Oie, nós somos a <br />TEIA Exp!</h3>
          <article className="flex flex-col md:flex-row md:px-10 px-6 uw:flex-col uw:px-96 uw:h-28">
            <p className="text-center text-lg md:pt-4 md:px-4 mac:px-20 mac:text-left uw:px-96 uw:text-2xl">
              Empreendemos durante a vida universitária e identificamos que existe um "gap" muito grande entre o que se ensina na faculdade e a vida profissional. Entendemos que as competências mais exigidas no mercado de trabalho hoje são subjetivas e a melhor forma de aprendê-las é consumindo experiências.</p>
            <p className="text-center text-lg pt-4 md:px-4 mac:px-20 mac:text-left uw:px-96 uw:text-2xl">
              Somos apaixonados por desenvolvimento humano, estudamos em escolas construtivistas e estamos revolucionando a forma que as empresas desenvolvem seus colaboradores para as competências mais exigidas alinhas aos pilares culturais das empresas.
            </p>
          </article>
          <svg className="about-wave absolute w-full md:-bottom-36 -z-10 lg:-bottom-48 xl:-bottom-60 mac:-bottom-72" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FBD8CA" fill-opacity="1" d="M0,192L120,202.7C240,213,480,235,720,213.3C960,192,1200,128,1320,96L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
          </svg>
      </section>
      <section className="max-w-screen-xl my-6 md:mt-16 mx-auto text-center p-6 lg:mt-28 mac:mt-48 uw:mt-80">
          <div id="blobs-main1" className="relative">
            <svg className="grey1 w-full absolute top-0 md:left-24 lg:left-8" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="#E9E7E7" d="M37.2,-58.1C49.3,-57.3,61.1,-49.7,65.3,-38.9C69.6,-28.1,66.2,-14,64.4,-1C62.7,12,62.4,24,57.5,33.6C52.6,43.2,43,50.5,32.6,50.8C22.2,51,11.1,44.3,-0.7,45.4C-12.5,46.6,-24.9,55.7,-31.9,53.5C-38.8,51.2,-40.2,37.6,-49.3,26.8C-58.5,16,-75.5,8,-77.3,-1C-79,-10,-65.4,-19.9,-54.1,-27.1C-42.8,-34.3,-33.8,-38.6,-25.2,-41.4C-16.5,-44.2,-8.3,-45.4,2.1,-49.1C12.5,-52.8,25,-58.9,37.2,-58.1Z" transform="translate(100 100)" /></svg>

            <svg className="orange5 absolute top-32 left-60 sm:top-96 sm:left-96 md:left-48" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FF9980" d="M41.3,-38.7C48.3,-34.3,45.2,-17.2,47.6,2.4C50.1,22,58.1,44.1,51.1,49.5C44.1,54.8,22,43.5,3.5,40C-15.1,36.5,-30.1,40.8,-42.1,35.5C-54,30.1,-62.8,15.1,-60.4,2.4C-58,-10.2,-44.3,-20.4,-32.3,-24.7C-20.4,-29.1,-10.2,-27.7,3.5,-31.2C17.2,-34.6,34.3,-43.1,41.3,-38.7Z" transform="translate(100 100)" /></svg>
            
            <svg className="orange6 absolute top-4 left-6 sm:top-44" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FF9980" d="M8.1,-15C12.7,-3.5,20.1,-1.8,29.4,9.2C38.6,20.2,49.7,40.5,45.1,42.2C40.5,43.9,20.2,27,2,25C-16.2,23,-32.5,35.9,-44.8,34.2C-57.2,32.5,-65.7,16.2,-61.9,3.8C-58,-8.6,-41.9,-17.2,-29.5,-28.6C-17.2,-40.1,-8.6,-54.4,-3.4,-51C1.8,-47.6,3.5,-26.4,8.1,-15Z" transform="translate(100 100)" /></svg>
          </div>
          <div className="relative lg:flex lg:flex-row md:w-full md:items-center">
            <img src={ jumpingImg } alt="Desenho pessoa saltando" className="md:max-w-xl md:ml-12 lg:m-0"/>
            <article className="flex flex-col lg:text-left">
              <h3 className="text-xl font-bold sm:text-2xl md:text-2xl py-2 md:py-4 uw:text-3xl">
                Acreditamos que todos podem ser criativos e empáticos
              </h3>
              <p className="text-center lg:text-left text-lg sm:text-xl sm:px-8 py-2 md:text-xl lg:text-xl lg:px-0 uw:text-2xl">
                Existimos para garantir que todos possam desenvolver habilidades e experimentarem o mundo real.
                <br />
                Com a volta das atividades após um longo e triste período de reclusão, o TEIA Exp vai conectar pessoas e lugares para estimular um crescimento mais humano nas empresas!
                <br />
                Existimos para garantir que todos possam desenvolver habilidades e experimentarem o mundo real.
              </p>
              <a target="_blank" href="https://www.teiaexp.com/blog" className="text-primary" rel="noreferrer">
                <Button color="secondary" text="Blog" /> 
              </a>
            </article>
          </div>
      </section>  
      <SmallFooter />  
    </>
  )
}

export default About;
